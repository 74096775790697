import React, {useEffect, useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";

const REACT_APP_APP_STORE_ID = process.env.REACT_APP_APP_STORE_ID;
const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;
const APP_NAME = 'Dɔgɔjɔ';

const HASH_TAG = "#";
const LINKED_IN_LINK = "https://www.linkedin.com/company/djamma-dev";
const FACEBOOK_LINK = "https://www.facebook.com/djammadev";
const TWITTER_LINK = "https://twitter.com/djammadev";
const APP_STORE_LINK = `https://apps.apple.com/fr/app/chiesulu/id${REACT_APP_APP_STORE_ID}?l=fr%3Fplatform#?platform=iphone`;
const PLAY_STORE_LINK = "https://play.google.com/store/apps/details?id=com.djammadev.dogojo";


const {$, grecaptcha} = window;

function Header(props) {
    const [isScrollLoaded, setIsScrollLoaded] = useState(false);
    useEffect(() => {
        // initialization of header
        $.HSCore.components.HSHeader.init($('#js-header'));
        $.HSCore.helpers.HSHamburgers.init('.hamburger');
    });

    useEffect(() => {
        if (!isScrollLoaded) {
            $.HSCore.components.HSScrollNav.init($('#js-scroll-nav'), {
                duration: 700
            });
            setIsScrollLoaded(true);
        }
    }, [isScrollLoaded]);

    return (
        <header id="js-header" className="u-header u-header--sticky-top u-header--show-hide u-header--toggle-section"
                data-header-fix-moment="100"
                data-header-fix-effect="slide">
            <div className="u-header__section u-shadow-v27 g-bg-white g-transition-0_3 g-py-12 g-py-20--md">
                <nav className="navbar navbar-expand-lg py-0 g-px-15">
                    <div className="container g-pos-rel">
                        {/* Logo */}
                        <a href={HASH_TAG} className="js-go-to navbar-brand u-header__logo">
                            <img className="u-header__logo-img u-header__logo-img--main g-width-40"
                                 src="/assets/img/logo.png" alt="Dogojo"/>
                        </a>
                        {/* End Logo */}

                        <div id="navBar" className="collapse navbar-collapse" data-mobile-scroll-hide="true">
                            {/* Navigation */}
                            <div className="navbar-collapse align-items-center flex-sm-row">
                                <ul id="js-scroll-nav"
                                    className="navbar-nav g-flex-right--xs text-uppercase w-100 g-font-weight-700 g-font-size-11 g-pt-20 g-pt-0--lg">
                                    <li className="nav-item g-mr-15--lg g-mb-12 g-mb-0--lg active">
                                        <a href={"#home"} className="nav-link p-0">Accueil <span
                                            className="sr-only">(current)</span></a>
                                    </li>
                                    <li className="nav-item g-ml-15--lg g-mb-12 g-mb-0--lg">
                                        <a href={"#contact"} className="nav-link p-0">Contact</a>
                                    </li>
                                </ul>
                            </div>
                            {/* End Navigation */}
                            <a className="btn btn-lg text-uppercase u-btn-primary g-font-weight-700 g-font-size-11 g-rounded-10 g-px-13 g-py-10 mb-0 g-ml-25--lg"
                               target={"_blank"} rel={"noreferrer"}
                               href="https://dogojo.djammadev.com">Essayer</a>
                        </div>

                        {/* Responsive Toggle Button */}
                        <button
                            className="navbar-toggler btn g-line-height-1 g-brd-none g-pa-0 g-pos-abs g-top-15 g-right-0"
                            type="button"
                            aria-label="Toggle navigation"
                            aria-expanded="false"
                            aria-controls="navBar"
                            data-toggle="collapse"
                            data-target="#navBar">
                            <span className="hamburger hamburger--slider">
                              <span className="hamburger-box">
                                <span className="hamburger-inner"/>
                              </span>
                            </span>
                        </button>
                        {/* End Responsive Toggle Button */}
                    </div>
                </nav>
            </div>
        </header>
    );
}

function Home(props) {
    useEffect(() => {
        // initialization of scroll animation
        $.HSCore.components.HSOnScrollAnimation.init('[data-animation]');
    });

    return (
        <section id="home" className="g-theme-bg-gray-light-v1 g-pt-90">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-7 ml-lg-auto d-flex text-center text-md-left g-font-size-16">
                        <div className="align-self-center">
                            <h2 className="text-uppercase g-line-height-1_3 g-font-size-36 g-mb-20 g-mb-30--lg"> {APP_NAME}
                                <br/><strong>Et le monde devient petit</strong></h2>
                            <p className="g-mb-20 g-mb-35--lg">
                                Les initiatives de la <strong>CPD</strong> (Cellule Pour le Développement) sont
                                nombreuses, cela s'explique par le nombre croissant de ses membres.
                                Il devient de plus en plus difficile d'être au courant de tout ce qui se passe au sein
                                du reseau. Nous avons donc pensé à
                                mettre en place un endroit unique où toutes les initiatives peuvent se retrouver.
                            </p>
                            <a target={"_blank"} href={APP_STORE_LINK} rel="noreferrer"
                               className="btn btn-md u-btn-primary g-font-weight-700 text-uppercase text-left g-rounded-10 g-py-10 g-mb-15 g-mx-10 g-mx-0--md g-mr-30--md">
                              <span className="float-right g-font-size-13">
                                Télécharger
                                <span
                                    className="d-block g-font-size-10 g-font-weight-400 g-opacity-0_6">App Store</span>
                              </span>
                                <i className="fa fa-apple float-left g-font-size-26 g-mr-15"/>
                            </a>
                            <a target={"_blank"} href={PLAY_STORE_LINK} rel="noreferrer"
                               className="btn btn-md u-btn-darkpurple g-font-weight-700 text-uppercase text-left g-rounded-10 g-py-10 g-mb-15 g-mx-10 g-mx-0--md">
                                <span className="float-right g-font-size-13">
                                    Télécharger
                                    <span
                                        className="d-block g-font-size-10 g-font-weight-400 g-opacity-0_6">Play Store</span>
                                </span>
                                <i className="fab fa-google-play float-left g-font-size-26 g-mr-15"/>
                            </a>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-5 g-overflow-hidden">
                        <img className="img-fluid"
                             src="/assets/img/home.png"
                             alt="Chiesulu"
                             data-animation="slideInUp"/>
                    </div>
                </div>
            </div>
        </section>
    );
}

function Contact(props) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [notification, setNotification] = useState('');
    const [hasError, setHasError] = useState(false);
    const [loading, setLoading] = useState(false);

    const resetForm = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setMessage('');
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        grecaptcha.ready(function () {
            grecaptcha.execute(RECAPTCHA_KEY, {action: 'submit'}).then(async function (token) {
                console.log(token);
                let data = {contact: {firstName, lastName, email, message}, captcha: token};
                setNotification('');
                try {
                    let res = await fetch('/api/data/contact', {
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        method: 'POST',
                        body: JSON.stringify(data)
                    });
                    let response = await res.json();
                    console.log('response', response);
                    if (res.status !== 200) {
                        throw new Error(JSON.stringify(response));
                    } else {
                        resetForm();
                        setHasError(false);
                        setNotification('Merci de votre intérêt !');
                    }
                } catch (error) {
                    console.log('error', error);
                    setHasError(true);
                    setNotification('Une erreur est survenue lors de la communication avec le serveur.');
                } finally {
                    setLoading(false);
                }
                console.log(data);
            });
        });
    }
    return (
        <section id="contact" className="g-bg-gray-light-v1 g-pt-90 g-pb-30 g-pb-90--lg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 g-mb-20 g-mb-0--lg">
                        <div className="text-uppercase g-mb-20">
                            <h4 className="g-font-weight-700 g-font-size-11 g-color-white g-mb-15">{props.index}.
                                {props.title}</h4>
                            <h2 className="g-font-size-36 g-color-white mb-0">Restons en <strong>contact</strong>
                            </h2>
                        </div>

                        <p className="g-font-size-16 g-color-white mb-0">
                            Laissez nous votre contact nous vous tiendrons informer dès qu'il y aura du nouveau
                            dans <strong>Dɔgɔjɔ</strong>
                        </p>
                    </div>

                    <div className="col-lg-7 g-pt-30--lg">
                        <form id="contact-form" onSubmit={onSubmit}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group g-mb-30">
                                        <input id="inputGroup1_1"
                                               className="form-control h-100 g-color-white g-placeholder-inherit g-bg-transparent g-bg-transparent--focus g-brd-white g-rounded-10 g-px-20 g-py-10"
                                               type="text"
                                               placeholder="Prénom *"
                                               required
                                               value={firstName}
                                               onChange={e => setFirstName(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group g-mb-30">
                                        <input id="inputGroup1_1"
                                               className="form-control h-100 g-color-white g-placeholder-inherit g-bg-transparent g-bg-transparent--focus g-brd-white g-rounded-10 g-px-20 g-py-10"
                                               type="text"
                                               placeholder="Nom *"
                                               required
                                               value={lastName}
                                               onChange={e => setLastName(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group g-mb-30">
                                        <input id="inputGroup1_2"
                                               className="form-control h-100 g-color-white g-placeholder-inherit g-bg-transparent g-bg-transparent--focus g-brd-white g-rounded-10 g-px-20 g-py-10"
                                               type="email"
                                               required
                                               placeholder="Email *"
                                               value={email}
                                               onChange={e => setEmail(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group g-mb-30">
                                        <textarea id="inputGroup1_3"
                                                  className="form-control g-resize-none g-color-white g-placeholder-inherit g-bg-transparent g-bg-transparent--focus g-brd-white g-rounded-10 g-px-20 g-py-10"
                                                  rows="7"
                                                  placeholder="Message"
                                                  value={message}
                                                  onChange={e => setMessage(e.target.value)}/>
                                    </div>

                                    <div className="text-center text-md-right">
                                        <button
                                            className="btn u-btn-white btn-md text-uppercase g-font-weight-700 g-font-size-12 g-color-black g-rounded-10 g-py-10 g-px-25 mb-0"
                                            disabled={loading}
                                            type='submit'>Envoyer
                                        </button>
                                        {loading && <CircularProgress
                                            size={24}
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />}
                                    </div>
                                </div>
                                {Boolean(notification) &&
                                <div className={`g-font-size-16 g-font-weight-800 g-color-white`}>
                                    <Alert severity={`${hasError ? "error" : "success"}`}>{notification}</Alert>
                                </div>}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

function Footer(props) {
    return (
        <footer>
            <ul className="list-inline d-table w-100 mb-0">
                <li className="list-inline-item d-table-cell g-width-1x">
                    <a className="btn btn-lg u-btn-indigo btn-block rounded-0 g-py-20" href={FACEBOOK_LINK}
                       target={"_blank"} rel="noreferrer"><i
                        className="fa fa-facebook"/></a>
                </li>
                <li className="list-inline-item d-table-cell g-width-1x">
                    <a className="btn btn-lg u-btn-pink btn-block rounded-0 g-py-20" href={LINKED_IN_LINK}
                       target={"_blank"} rel="noreferrer"><i
                        className="fa fa-linkedin"/></a>
                </li>
                <li className="list-inline-item d-table-cell g-width-1x">
                    <a className="btn btn-lg u-btn-blue btn-block rounded-0 g-py-20" href={TWITTER_LINK}
                       target={"_blank"} rel="noreferrer"><i
                        className="fa fa-twitter"/></a>
                </li>
            </ul>
            <p style={{textAlign: 'center'}}>{new Date().getFullYear()} &copy; All Rights Reserved. <span
                className="djamma-dev"><span>Djamma</span> Dev</span></p>
        </footer>
    );
}

function GoTop(props) {
    useEffect(() => {
        $.HSCore.components.HSGoTo.init('.js-go-to');
    });
    return (
        <a className="js-go-to u-go-to-v1" href={HASH_TAG}
           data-type="fixed"
           data-position='{
           "bottom": 15,
           "right": 15
         }'
           data-offset-top="400"
           data-compensation="#js-header"
           data-show-effect="zoomIn">
            <i className="hs-icon hs-icon-arrow-top"/>
        </a>
    );
}

function App() {
    return (
        <>
            <Header/>
            <Home/>
            <Contact index={"01"} title={"Nous contacter"}/>
            <Footer/>
            <GoTop/>
        </>
    );
}

export default App;
